// Imports
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import moment from 'moment';
import Select from '../../../ui/Input/CustomSelect';
import CheckboxList from './checkboxList';

// UI imports
import * as s from './profile.styles';
import Button from '../../../ui/Button';
import Modal from '../../../ui/Modal/GroupsModal/groupsModal';

// App imports
import params from '../../../setup/config/params';
import { logout, setSection, subscriptionRetrive, cancelSubscription, groupMembers, setRefreshToken } from '../api/actions/query';

const Profile = () => {
  const [isLoading, isLoadingToggle] = useState(false);
  const [subscriptionData, setSubscription] = useState(null);
  const [subscriptionItemId, setSubscriptionItemId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [allMembers, setAllMembers] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const { user, members, selectedGroups, token } = useSelector((state) => state.auth);
  const data = JSON.parse(user);

  const numberGroups = Object.keys(selectedGroups).length;
  const numbermembers = Object.keys(members).length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSection('profile'));
  }, []);

  const getSubscriptionInfo = async () => {
    if (data.stripeCustomer && data.stripeSubscription) {
      const subscriptionInfo = await subscriptionRetrive(data.stripeSubscription);

      setSubscription(subscriptionInfo.data.result.data.subscription);
      setSubscriptionItemId(subscriptionInfo.data.result.data.subscription.items.data[0].id);
    }
  };

  useEffect(() => {
    getSubscriptionInfo();
  }, [data.stripeSubscription]);

  const onLogout = () => {
    // eslint-disable-next-line no-alert
    const check = window.confirm('Are you sure you want to logout?');

    if (check) {
      dispatch(logout());
    }
  };

  const getDateInfo = () => {
    if (!data.stripeSubscription) {
      return 'No Subscription';
    }
    const date = moment.unix(subscriptionData?.current_period_end).format('LL');
    return date;
  };

  const cancelStripe = async () => {
    if (!data.stripeSubscription) {
      return null;
    }
    const cancel = await cancelSubscription(data, data.stripeCustomer);
    const customerPortal = cancel.data.result.data.url;

    if (customerPortal) {
      window.open(customerPortal);
    }

    if (cancel.data?.data?.status === 'canceled') {
      dispatch(logout());
    }

    return null;
  };

  const listGroups = selectedGroups?.map((group) => ({
    value: group.groupId,
    label: group.name,
  }));

  const fetchMembers = async (groupId, groupName) => {
    const allMembers = await groupMembers(groupId, token);

    if (allMembers.data) {
      dispatch(setRefreshToken(allMembers.data.result.refresh_token));

      const mapping = allMembers.data?.result?.members.map((member) => ({
        id: member.id,
        avatar: member.avatar_url,
        name: member.name,
        group: groupName,
        // eslint-disable-next-line eqeqeq
        status: members.filter((m) => m.id == member.id).length > 0 ? 'Active' : 'Inactive',
      }));
      setAllMembers(mapping);
    }
  };

  const onChangeGroup = (value) => {
    const groupName = value.label;
    const groupId = value.value;
    setGroupId(groupId);
    fetchMembers(groupId, groupName);
  };

  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`User Profile · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.ProfileWrapper>
      <s.ProfileContainer>
        <s.ProfileContent>
          <s.TextTitle>Profile</s.TextTitle>
          <s.Profile>
            <Avatar alt={data.name} src={data.avatar} style={{ width: 80, height: 80 }} />
            <s.TextSub>{data.name}</s.TextSub>
            <Button style={{ width: 90, height: 30, background: '#F08421' }} title='Logout' onClick={onLogout} />
          </s.Profile>
        </s.ProfileContent>
        <s.BillingContent>
          <s.BillingHeader>
            <s.BillingHeaderButton>
              <s.TextTitle>Membership and Billing</s.TextTitle>
              <Button style={{ width: 200, height: 50, background: '#F08421' }} title='Manage Subscription' onClick={cancelStripe} />
              <Button style={{ width: 200, height: 40, background: '#F08421' }} title='Add Group' onClick={handleOpen} />
            </s.BillingHeaderButton>
            <s.BillingHeaderData >
              <div style={{ borderBottom: '1px solid #E4E4E4' }}>
                <s.TextSub >{data.email}</s.TextSub>
              </div>
              <s.TextSub2 >Your next payment - <span style={{ fontWeight: 'bold' }}>{getDateInfo()}</span> </s.TextSub2>
            </s.BillingHeaderData>
          </s.BillingHeader>
          <s.BillingDescription>
            <s.BillingDescriptionContent1>
              <s.TextTitle style={{ margin: '10px' }}>Manage your Team</s.TextTitle>
              <s.TextSub2 style={{ margin: '10px' }}>
                View your team members that you have added for proper tracking of product/app development results linked to your Gitlab group(s).
                </s.TextSub2>
                <div>
                  <Select
                    style={{ fontSize: '16px', margin: '15px', textTransform: 'capitalize' }}
                    onChange={onChangeGroup}
                    options={listGroups}
                    placeholder='Select a Group'
                  />
                </div>
            </s.BillingDescriptionContent1>
            <s.BillingDescriptionContent2>
              <div style={{ display: 'grid', justifyItems: 'center', height: '120px', width: '120px', borderRadius: '100px', border: '2px solid #FFFFFF' }}>
              <s.DashboardContenticonText>
                  {numbermembers}
                </s.DashboardContenticonText>
                <s.DashboardContenticonText2>
                  Active Devs
                </s.DashboardContenticonText2>
              </div>
              <div style={{ display: 'grid', justifyItems: 'center', height: '150px', width: '150px', borderRadius: '150px', backgroundColor: 'white' }}>
              <s.DashboardContenticonText>
                  {numberGroups}
                </s.DashboardContenticonText>
                <s.DashboardContenticonText2>
                  Groups
                </s.DashboardContenticonText2>
              </div>
            </s.BillingDescriptionContent2>
          </s.BillingDescription>
        </s.BillingContent>
        { allMembers && (
          <div style={{ margin: '20px' }}>
        <CheckboxList
        stripeSubscriptionItem={subscriptionItemId}
        allMembers={allMembers}
        groupId={groupId}
        >
        </CheckboxList>
        </div>
        )}
        <Modal isOpen={openModal} handleClose={handleClose} />
      </s.ProfileContainer>
      </s.ProfileWrapper>
      </>
  );
};

export default Profile;
