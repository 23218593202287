// Imports
import * as queryString from 'query-string';

// App imports
import routes from '../routes';
import params from '../config/params';
import { URL_WEB, OAUTH_GITLAB_ID } from '../config/env';

// gitlab
export default function gitlab() {
  const url = queryString.stringifyUrl(
    {
      url: 'https://gitlab.com/oauth/authorize',
      query: {
        client_id: OAUTH_GITLAB_ID,
        redirect_uri: `${URL_WEB}${routes.pagesAuthorize.path}/`,
        scope: 'api+read_user+profile+email',
        response_type: 'code',
        state: JSON.stringify({
          provider: params.user.oauth.providers.gitlab.key,
        }),
      },
    },
    { encode: false },
  );

  return url;
}
