// Imports
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import * as queryString from 'query-string';
import moment from 'moment';

// UI imports
import './style.css';

// App imports
import params from '../../../setup/config/params';
import routes from '../../../setup/routes';
import {
  setAuth,
  loginSetUser,
  loginSetUserLocalStorage,
  setRefreshToken,
  setGroups,
  setProjects,
  setIssues,
  setActivity,
} from '../../user/api/actions/query';
import { authorize } from '../../user/api/actions/mutation';
import { URL_WEB } from '../../../setup/config/env';

// Component
const Authorize = ({ history, location }) => {
  // state
  const dispatch = useDispatch();

  // on load
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    process();
  }, []);

  // process
  const process = async () => {
    const query = queryString.parse(location.search);

    if (query.code && query.state) {
      let redirectTo = routes.pagesHome.path;

      try {
        const data = await authorize(query.code);

        if (data.data.result) {
          const { token, user, groups, projects, issues, activity } = data.data.result;

          const subscriptionUser = JSON.parse(user);
          const doubledArray = activity.map(
            (nested) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              nested.map((activity) => ({
                title: activity.title,
                author: activity.author_name,
                date: moment(activity.committed_date).format(
                  'MM/DD/YYYY HH:mm',
                ),
                url: activity.web_url,
              })),
            // eslint-disable-next-line function-paren-newline
          );

          const lastActivities = doubledArray
            .filter((m) => m.length > 0)
            .flat();
          const today = new Date();

          // Disable 60 days filter issue#
          const before60Days = new Date().setDate(today.getDate() - 60);
          const filterActivities = lastActivities.filter((item) => {
            const itemTime = new Date(item.date).getTime();
            return itemTime;
          });

          // sort activities by date
          filterActivities.sort((a, b) => {
            const c = new Date(a.date);
            const d = new Date(b.date);
            return d - c;
          });

          dispatch(loginSetUser(token, user));
          dispatch(setGroups(groups));
          dispatch(setProjects(projects));
          dispatch(setIssues(issues));
          dispatch(setActivity(filterActivities));

          const refreshToken = JSON.parse(user);
          dispatch(setRefreshToken(refreshToken.refreshToken));

          window.sessionStorage.setItem('token', refreshToken.refreshToken);
          window.sessionStorage.setItem('auth', 'groups');
          dispatch(setAuth('groups'));

          loginSetUserLocalStorage(token, user);

          const isActive = subscriptionUser?.subscriptionActive;

          redirectTo = !isActive ? routes.PagesSubscription.path : routes.userDashboard.path;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        history.push(redirectTo);
      }
    } else {
      history.push(routes.pagesHome.path);
    }
  };

  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`Authorizing... · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <div className='pages-authorize'>
        <p>
          <img src={`${URL_WEB}/images/beanEater.gif`} alt='loading...' />{'  '}
        </p>
        <p>
          Authorizing....
        </p>
      </div>
    </>
  );
};

export default Authorize;
