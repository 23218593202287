// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function stripeRetrive(id) {
  return axios.get(`${URL_API}/stripe/retrive`, {
    params: id,
  });
}
