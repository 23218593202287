import styled from '@emotion/styled';

export const ProfileWrapper = styled.div`
  height: 900px;
  overflow-y: scroll;
`;

export const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .7fr 1fr 1fr;
  margin: 20px;
`;

export const ProfileContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .2fr .8fr;
  margin: 20px;
  border-bottom: 1px solid #E4E4E4;
`;

export const TextTitle = styled.div`
font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 24px;
color: #3D3D3E;
`;

export const TextSub = styled.div`
margin: 10px;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
color: #263742;
`;

export const TextSub2 = styled.div`
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
color: #7E7E7E;
`;

export const Profile = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .8fr .2fr .2fr;
  margin: 20px;
  align-items: center;
  justify-items: center;
`;

export const BillingContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  margin: 20px;
`;

export const BillingHeader = styled.div`
  display: grid;
  grid-template-columns: .4fr .6fr;
`;

export const BillingHeaderButton = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
`;

export const BillingHeaderData = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
`;

export const BillingDescription = styled.div`
  display: grid;
  grid-template-columns: .4fr .6fr;
  background-color: #F1F1F1;
`;

export const BillingDescriptionContent1 = styled.div`
  display: grid;
  grid-template-rows: .5fr 1fr 1fr;
`;

export const BillingDescriptionContent2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
`;

export const DashboardContenticonText = styled.div`
display: grid;
padding-top: 10px;
justify-items: center;
align-items: center;
justify-self: center;
font-weight: 400;
font-style: normal;
font-size: 36px;
line-height: 36px;
color: #F08421;
`;

export const DashboardContenticonText2 = styled.div`
padding: 5px;
align-items: center;
justify-self: center;
font-weight: 700;
font-style: normal;
font-size: 14px;
line-height: 14px;
color: rgba(16, 22, 30, 0.4);
`;

