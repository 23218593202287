import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Avatar from '../../Avatar/avatar';
import GroupList from './checklistGroup';
import * as s from './groupsStyle';

const style = ({
  display: 'grid',
  justifyItems: 'center',
  alignItems: 'center',
  padding: '3rem',
});

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px',
    boxSizing: 'border-box',
    zindex: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

export default function GroupsModal({ isOpen, handleClose }) {
  const { user } = useSelector((state) => state.auth);
  const data = JSON.parse(user);

  const classes = useStyles();

  const body = (
    <div className={classes.paper}>
        <s.homeContainer>
        <s.authContainer>
        <s.userProfile>
         <Avatar name={data.name} avatar={data.avatar} />
         <s.textProfile>
           <s.nameText>{data.name}</s.nameText>
           <s.emailText>{data.email}</s.emailText>
         </s.textProfile>
        </s.userProfile>
        <s.description>
          <s.descriptionText>Select a group and its members</s.descriptionText>
          <s.descriptionText2>We recommend you have at least Report privileges in your Gitlab group to proceed</s.descriptionText2>
        </s.description>
        <s.listContainer>
        <GroupList />
        </s.listContainer>
        </s.authContainer>
        </s.homeContainer>

      <GroupsModal />
    </div>
  );

  return (
    <div>
      <Modal
        style={{ ...style }}
        open={isOpen}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </div>
  );
}
