import React from 'react';
import { Doughnut } from 'react-chartjs-2';
/* eslint-disable */
function PieJs({ issuesByDev, projectName }) {
  const data = {
    labels: Object.keys(issuesByDev).map((m) =>
      m === 'undefined' ? 'No Assignee' : m,
    ),
    datasets: [
      {
        data: Object.values(issuesByDev),
        backgroundColor: [
          '#003f5c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600',
        ],
      },
    ],
  };

  return (
    <div
      style={{
        border: '1px solid #DDDDDD',
        boxSizing: 'border-box',
        borderRadius: '20px',
        padding: '30px',
      }}
    >
      <h4
        style={{
          marginBottom: '40px',
          marginLeft: '20%',
          fontSize: '16px',
          color: 'rgba(0, 0, 0, 0.8)',
          textAlign: 'left',
        }}
      >
        Work load by project
      </h4>
      <Doughnut data={data} legend={{ position: 'left' }} />
      <h4 style={{ textAlign: 'left', fontSize: '14px', color: '#6D7176' }}>
        Showing <span style={{ color: '#EB8E21' }}>{projectName.label}</span>
      </h4>
    </div>
  );
}
export default PieJs;
