import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#fee2f8',
    backgroundImage: 'linear-gradient(315deg, #20bf55 0%, #dcf8ef 54%)',
    borderRadius: '10%',

  },
}));

export default function CheckboxListSecondary({ members, title }) {
  const classes = useStyles();

  return (
      <>
    <List dense className={classes.root}>
        <h4 style={{ margin: '20px' }}>{title}</h4>
      {members.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value.name}`;
        return (
          <ListItem key={value.name} button>
            <ListItemAvatar>
              <Avatar
                alt={value.name}
                src={value.avatar_url ? value.avatar_url : value.avatar}
              />
            </ListItemAvatar>
            <ListItemText id={labelId} primary={value.name} />
          </ListItem>
        );
      })}
    </List>
    </>
  );
}
