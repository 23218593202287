import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import Button from '../../Button/CustomButton';
import Alert from '../../Alert/alert';
import { updateSubscriptionGroup, subscriptionRetrive } from '../../../modules/user/api/actions/query';
import routes from '../../../setup/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    maxWidth: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 300,
    maxHeight: 300,
  },
  button: {
    display: 'grid',
    margin: '20px',
    justifyContent: 'center',
  },
  title: {
    flex: '1 1 100%',
  },
}));

export default function CheckboxList() {
  const { groups, selectedGroups, members, user } = useSelector((state) => state.auth);
  const userData = JSON.parse(user);
  const history = useHistory();

  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [checkedMembers, setCheckedMembers] = useState([]);
  const [groupList, setGroupList] = useState(true);
  const [groupSelected, setGroupSelected] = useState([]);
  const [membersFilter, setMembersFilter] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [subscriptionItemId, setSubscriptionItemId] = useState(null);

  useEffect(() => {
    const getSubscriptionInfo = async () => {
      if (userData.stripeSubscription) {
        const subscriptionInfo = await subscriptionRetrive(userData.stripeSubscription);
        setSubscriptionItemId(subscriptionInfo.data.result.data.subscription.items.data[0].id);
      }
    };

    getSubscriptionInfo();
  }, []);

  const groupsFilter = groups.filter((o1) => {
    return !selectedGroups.some((o2) => {
      // eslint-disable-next-line eqeqeq
      return o1.id == o2.groupId;
    });
  });

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleStripe = async () => {
    if (userData.stripeSubscription) {
      const quantity = members.length + checkedMembers.length;
      const group = checked;
      const updateStripe = await updateSubscriptionGroup(subscriptionItemId, quantity, checkedMembers, userData, group);
      if (updateStripe.data.result.data.quantity === quantity) {
        handleClose();
        // eslint-disable-next-line no-alert
        alert('Members Updated');
        history.push(routes.userDashboard.path);
      }
    }
    handleClose();
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleMembers = (value) => () => {
    const currentIndex = checkedMembers.indexOf(value);
    const newChecked = [...checkedMembers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedMembers(newChecked);
  };

  const handleClick = () => {
    setGroupSelected(checked[0]);
    // eslint-disable-next-line radix
    const filter = checked[0].members.filter((element) => element.id !== parseInt(userData.id));
    setMembersFilter(filter);
    setGroupList(false);
  };

  const handleCheckout = () => {
    handleClickOpen();
  };

  const getButtonTitle = () => {
    const totalMembers = members.length + checkedMembers.length;

    const price = totalMembers * 2.5;
    return `${totalMembers} Devs - ${price} USD / montly`;
  };

  return (
    <>
    {groupList && (
    <>
    <List dense className={classes.root}>
      {groupsFilter.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value.id}`;
        return (
          <ListItem style={{ marginBottom: '15px' }} key={value.id} button>
            <ListItemAvatar>
              <Avatar
                alt={`${value.name}`}
                src={`${value.avatar}`}
              />
            </ListItemAvatar>
            <ListItemText style={{ textTransform: 'capitalize', color: '#8B9096' }} id={labelId} primary={`${value.name}`} secondary={`${value.level}`}/>
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(value)}
                checked={checked.indexOf(value) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <div className={classes.button}>
        <Button title={checked.length === 0 ? 'Select a Group' : 'Continue'} disable={checked.length === 0} onClick={handleClick} />
    </div>
     </>
    )}
    { !groupList && (
        <>
        <List dense className={classes.root}>
        {membersFilter.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.id}`;
          return (
            <ListItem style={{ marginBottom: '15px' }} key={value.id} button>
              <ListItemAvatar>
                <Avatar
                  alt={`${value.name}`}
                  src={`${value.avatar_url}`}
                />
              </ListItemAvatar>
              <ListItemText style={{ textTransform: 'capitalize', color: '#8B9096' }} id={labelId} primary={`${value.name}`}/>
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleToggleMembers(value)}
                  checked={checkedMembers.indexOf(value) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
        <Typography className={classes.title} color="#949494" variant="subtitle2" component="div">
          {members.length} Devs actives + {checkedMembers.length} new Dev(s)
        </Typography>
        <div className={classes.button}>
          <Button title={checkedMembers.length === 0 ? 'Select a Member' : getButtonTitle()} disable={checkedMembers.length === 0} onClick={handleCheckout} />
        </div>
        </>
    )}
    { openAlert && (
        <Alert
        title={'Do you want to continue?'}
        description={`By checking the box you will receive a charge for ${(members.length + checkedMembers.length) * 2.5} USD`}
        isOpen={openAlert}
        handleClose={handleClose}
        accepted={handleStripe} />
    )}
  </>
  );
}
