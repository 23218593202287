import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { loadStripe } from '@stripe/stripe-js';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { STRIPE_KEY } from '../../../setup/config/env';
import Button from '../../../ui/Button/CustomButton';
import { stripeCheckout, loginCheckout, setAuth } from '../../user/api/actions/query';
import routes from '../../../setup/routes';

const stripePromise = loadStripe(STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    maxWidth: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 300,
    maxHeight: 300,
  },
  button: {
    display: 'grid',
    marginTop: '15px',
    justifyContent: 'center',
    marginBottom: '15px',
  },
  footer: {
    marginLeft: '40px',
    padding: '10px',
    fontSize: '14px',
    fontWeight: 500,
    color: 'rgba(139, 144, 150, 0.8)',
  },
  divButton: {
    display: 'grid',
    gridTemplateColumns: '.4fr .1fr .4fr',
    justifyContent: 'center',
  },
  divLine: {
    display: 'grid',
    justifyContent: 'center',
    height: '0px',
    width: '128px',
    border: '1px solid #DDDDDD',
    alignItems: 'center',
    margin: '8px',
  },
  footerButton: {
    display: 'grid',
    justifyContent: 'center',
    color: 'rgba(139, 144, 150, 0.8)',
    fontWeight: 500,
    fontSize: '14px',
  },
}));

export default function CheckboxList() {
  const { selectedGroups, user } = useSelector((state) => state.auth);
  const member = selectedGroups[0].members;
  const userData = JSON.parse(user);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClick = async (checked) => {
    loginCheckout(selectedGroups, checked);

    window.localStorage.setItem('user', user);
    window.sessionStorage.setItem('members', JSON.stringify(checked));
    window.sessionStorage.setItem('auth', 'checkout');
    dispatch(setAuth('checkout'));

    const quantity = checked.length;

    const stripe = await stripePromise;
    const data = await stripeCheckout(quantity, userData.trial);

    stripe.redirectToCheckout({
      sessionId: data.data.result.data.id,
    });
  };

  const handleAllMembersClick = () => {
    setChecked(member);
  };

  const getAccessLevel = (level) => {
    switch (level) {
      case 50:
        return 'Owner';
      case 40:
        return 'Maintainer';
      case 30:
        return 'Developer';
      case 20:
        return 'Reporter';
      case 10:
        return 'Guest';
      case 5:
        return 'Minimal Access';
      case 0:
        return 'No Access';
      default:
        return 'Undefined';
    }
  };

  const getButtonTitle = (checked) => {
    if (userData.trial) {
      return 'Try Now';
    }
    const price = (checked.length) * 2.5;
    return `${checked.length} Devs - ${price} USD /montly`;
  };

  const getButtonAllTitle = (member) => {
    const price = (member.length - 5) * 2.5;
    return `${member.length} Devs - ${price} USD /montly`;
  };

  return (
    <>
    <div style={{ marginBottom: '10px', fontStyle: 'normal', fontWeight: 'bold', fontSize: '14px', color: '#263742' }}>{`${member.length} devs`}</div>
    <List dense className={classes.root}>
      {selectedGroups[0].members.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value.id}`;
        return (
          <ListItem style={{ marginBottom: '15px' }} key={value.id} button>
            <ListItemAvatar>
              <Avatar
                alt={`${value.name}`}
                src={`${value.avatar_url}`}
              />
            </ListItemAvatar>
            <ListItemText style={{ textTransform: 'capitalize', color: '#8B9096' }}
            id={labelId} primary={`${value.name}`} secondary={`${getAccessLevel(value.access_level)}`}/>
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(value)}
                checked={checked.indexOf(value) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <div className={classes.footer}>We will charge you 2.5 <span style={{ color: 'black' }}>USD/per dev</span></div>
    <div className={classes.button}>
      <Button
      title={getButtonTitle(checked)}
      disable={checked.length === 0}
      style={{ textTransform: 'capitalize', width: '250px' }}
      onClick={() => handleClick(checked)}
      />
    </div>
    <div className={classes.divButton}>
      < div className={classes.divLine} />
      <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Or</div>
      < div className={classes.divLine} />
    </div>
    <div className={classes.button}>
      <Button
      title={getButtonAllTitle(member)}
      style={{ textTransform: 'capitalize', width: '250px' }}
      onClick={handleAllMembersClick}
      />
    </div>
    <div className={classes.footerButton}>This includes 5 free devs</div>
  </>
  );
}
