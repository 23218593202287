import styled from '@emotion/styled';

export const DashboardContentContainer = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 170px 1fr;
  padding: 24px;
  background-color: #F9F9F9;
`;

export const DashboardContentHeader = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 10px;
`;

export const DashboardContentHeaderIcons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0px;
`;

export const DashboardContenticon = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr .5fr;
  align-items: center;
  justify-self: center;
  padding: 10px;
`;

export const DashboardContenticonText = styled.div`
margin: 5px;
align-items: center;
justify-self: center;
font-weight: 400;
font-style: normal;
font-size: 36px;
line-height: 36px;
color: #F08421;
`;

export const DashboardContenticonText2 = styled.div`
margin: 5px;
align-items: center;
font-weight: 700;
font-style: normal;
font-size: 14px;
line-height: 14px;
color: rgba(16, 22, 30, 0.4);
`;

export const DashboardTable = styled.div`
  margin: 0px;
`;
