import styled from '@emotion/styled';

export const wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #0B315E;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.25);
`;

export const homeContainer = styled.div`
height: 100%;
width: 100%;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 1fr 2fr .1fr;
justify-items: center;
`;

export const wrapperText = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 30px 60px;
padding-top: 50px;
color: #FFFFFF;
`;

export const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
`;

export const authContainer = styled.div`
display:grid;
grid-template-columns: 1fr;
grid-template-rows: .4fr .2fr .6fr 1fr;
width: 352px;
height: 290px;
background: #F9F9F9;
border-radius: 10px;
`;

export const buttonContainer = styled.div`
display: grid;
margin: 24px;
justify-items: center;
align-items: center;
`;

export const welcomeText = styled.div`
padding-top: 40px;
font-style: normal;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
color: rgba(255, 255, 255, 0.8);
`;

export const textDescriptionTitle = styled.div`
justify-items: left !important;
margin: 24px;
font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 24px;
color: rgba(0, 0, 0, 0.8);
`;

export const textDescription = styled.div`
margin-left: 24px;
font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
color: #6D7176;
`;

export const termsText = styled.div`
margin-left: 24px;
padding-bottom: 24px;
font-family: Lato;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #8B9096;
`;

export const footerText = styled.div`
font-family: Lato;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: #ffffff;
`;

