import React, { Component } from 'react';

import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
  CellUnits,
} from 'scheduler-react/lib';
import 'scheduler-react/lib/css/style.css';
import moment from 'moment';
import withDragDropContext from './withDnDContext';

class CalendarScheduler extends Component {
  constructor(props) {
    super(props);
    const { project } = this.props;
    const projectName = project.label;
    const Url = project.url;
    const projectImg = project.avatar;

    const schedulerData = new SchedulerData(
      // eslint-disable-next-line new-cap
      new moment().format(DATE_FORMAT),
      ViewTypes.Custom1,
      false,
      false,
      {
        customCellWidth: '7%',
        nonAgendaDayCellHeaderFormat: 'M/D|HH:mm',
        views: [
          {
            viewName: 'Week',
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: 'Two weeks',
            viewType: ViewTypes.Custom1,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: 'Month',
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
        project: projectName,
        projectUrl: Url,
        projectImage: projectImg,
      },
      {
        getCustomDateFunc: this.getCustomDate,
        isNonWorkingTimeFunc: this.isNonWorkingTime,
      },
    );
    schedulerData.localeMoment.locale('en');

    this.state = {
      viewModel: schedulerData,
    };
  }

  render() {
    const { viewModel } = this.state;
    const { events, resources } = this.props;

    const schedulerData = this.state.viewModel;
    schedulerData.setResources(resources);
    schedulerData.setEvents(events);

    return (
      <div>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemPopoverTemplateResolver={
            this.eventItemPopoverTemplateResolver
          }
          toggleExpandFunc={this.toggleExpandFunc}
        />
      </div>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    // schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective,
    );
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    // schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    // schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor,
  ) => {
    return (
      <React.Fragment>
        <div>
          <h2 style={{ marginTop: '10px' }}>{title}</h2>
          <div>
           <span style={{ fontWeight: 'bold' }}>Assignee: </span>{eventItem.author}
          </div>
          <div style={{ textAlign: 'center', margin: '10px' }}>
            <img style={{ width: '80px', height: '80px', borderRadius: '7em' }} src={eventItem.img} />
        </div>
        <h5 style={{ marginTop: '10px', textAlign: 'center' }}>
          {start.format('LL')} - {end.format('LL')}
        </h5>
        </div>
      </React.Fragment>
    );
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;
    if (date !== undefined) {
      selectDate = date;
    }

    let startDate =
      num === 0 ?
        selectDate :
        schedulerData
          .localeMoment(selectDate)
          .add(2 * num, 'days')
          .format(DATE_FORMAT);
    let endDate = schedulerData
      .localeMoment(startDate)
      .add(1, 'days')
      .format(DATE_FORMAT);
    let cellUnit = CellUnits.Hour;
    if (viewType === ViewTypes.Custom1) {
      const monday = schedulerData
        .localeMoment(selectDate)
        .startOf('week')
        .format(DATE_FORMAT);
      startDate =
        num === 0 ?
          monday :
          schedulerData
            .localeMoment(monday)
            .add(2 * num, 'weeks')
            .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, 'weeks')
        .endOf('week')
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    } else if (viewType === ViewTypes.Custom2) {
      const firstDayOfMonth = schedulerData
        .localeMoment(selectDate)
        .startOf('month')
        .format(DATE_FORMAT);
      startDate =
        num === 0 ?
          firstDayOfMonth :
          schedulerData
            .localeMoment(firstDayOfMonth)
            .add(2 * num, 'months')
            .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, 'months')
        .endOf('month')
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    }

    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if (schedulerData.cellUnit === CellUnits.Hour) {
      const hour = localeMoment(time).hour();
      if (hour < 1) {
        return true;
      }
    } else {
      const dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return true;
      }
    }

    return false;
  };
}

export default withDragDropContext(CalendarScheduler);
