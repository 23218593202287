import styled from '@emotion/styled';

export const wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #20bf55;
  background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%);
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.25);
`;

export const container = styled.div`
height: 100%;
width: 100%;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: .3fr .7fr;
justify-items: center;
`;

export const paymentHeader = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: .6fr .2fr .2fr;
justify-items: center;
align-items: center;
`;

export const paymentContent = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: .1fr .6fr .1fr;
grid-gap: 20px;
justify-items: center;
align-items: center;
`;

export const paymentDescription = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
justify-items: center;
grid-gap: 20px;
`;

