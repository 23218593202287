// Unset user token and info in localStorage and cookie
export default function logoutUnsetUserLocalStorage() {
  // Remove token
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('groups');
  window.localStorage.removeItem('members');

  window.sessionStorage.removeItem('token');
  window.sessionStorage.removeItem('auth');
  window.sessionStorage.removeItem('members');
  window.sessionStorage.removeItem('groups');
}
