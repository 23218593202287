// Imports
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// UI imports
import './style.css';
import 'boxicons';
// App imports
import Sidebar from '../Sidebar';

// Component
const Layout = ({ children }) => {
  const { user, section } = useSelector((state) => state.auth);
  const user2 = (window.localStorage.getItem('user'));
  const data = JSON.parse(user);
  const doubleCheck = JSON.parse(user2);

  const [isSidebarOpen, setSidebarState] = useState(true);

  const handleSidebar = () => {
    setSidebarState(!isSidebarOpen);
  };

  const getTitle = (section) => {
    switch (section) {
      case 'home':
        return 'In your Gitlab';
      case 'report':
        return 'Progress report';
      case 'groups':
        return 'My Groups';
      case 'projects':
        return 'My Projects';
      case 'profile':
        return 'Account';
      default:
        return '';
    }
  };

  const isActive = data?.subscriptionActive;
  const isActiveNow = doubleCheck?.subscriptionActive;

  const menuButtonStyle = isSidebarOpen ? { backgroundColor: '#F08421' } : { backgroundColor: 'rgba(139, 144, 150, 0.8)' };
  const commonLayout = (!user || !isActive) ? { display: 'flex', flexDirection: 'row', flex: 1 } : { display: 'grid', gridTemplateColumns: '.1fr 2fr' };
  const commonMain = (!user || !isActive) ? { display: 'flex', flexDirection: 'column', flex: 1 } : { display: 'grid', gridTemplateRows: '72px 1fr' };

  // render
  return (
    <div style={ commonLayout } className='common-layout'>
      {/* header */}
      {user && (isActive || isActiveNow) && (
        <Sidebar
        user={data}
        isSidebarOpen={isSidebarOpen}
        >
        </Sidebar>
      )}

      {/* content */}
      <main style={commonMain}>
      {user && isActive && (
                <div className='header'>
                <div className='header-button' onClick={handleSidebar}>
                  <div style={menuButtonStyle} className='menu' ></div>
                  <div style={menuButtonStyle} className='menu'></div>
                  <div style={menuButtonStyle} className='menu'></div>
                </div>
                <div className='header-text'>{getTitle(section)}</div>
              </div>
      )}
        {children}
      </main>

    </div>
  );
};

export default Layout;
