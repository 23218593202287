// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function updateSubscription(id, quantity, newMembers, userData, groupId) {
  return axios.get(`${URL_API}/stripe/updateSubscription`, {
    params: { subscriptionId: id, productQuantity: quantity, members: newMembers, user: userData, groupid: groupId },
  });
}
