import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import StickyHeadTable from '../../../ui/Charts/table';
import { setSection } from '../api/actions/query';

import * as s from './projects.styles';
import params from '../../../setup/config/params';

const columns = [
  {
    id: 'name',
    label: 'Project Name',
    backgroundColor: '#008000',
    align: 'start',
  },
  {
    id: 'lastActivity',
    label: 'Last Activity',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'url',
    label: '',
    minWidth: 100,
    align: 'center',
  },
];

// Component
const Groups = () => {
  const { user, projects } = useSelector((state) => state.auth);
  const merged = [].concat(...projects);

  const projectsTable = merged.map((project) => ({
    name: project.name,
    lastActivity: moment(project.last_activity_at).format('lll'),
    url: project.web_url,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSection('projects'));
  }, []);

  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`User Projects · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.DashboardWrapper>
        <s.DashboardContentContainer>
          <s.DashboardContentTable>
            <StickyHeadTable
              columns={columns}
              rows={projectsTable}
              tableTitle={'My Projects'}
            ></StickyHeadTable>
          </s.DashboardContentTable>
        </s.DashboardContentContainer>
      </s.DashboardWrapper>
    </>
  );
};

export default Groups;
