// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function contactUs(formData) {
  return axios.get(`${URL_API}/common/contactUs`, {
    params: formData,
  });
}
