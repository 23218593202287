// Imports
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// App imports
import { useSelector } from 'react-redux';
import { URL_WEB } from '../../../setup/config/env';
import params from '../../../setup/config/params';
import GroupsCheck from '../../common/AuthCheck/checkGroups';
import Avatar from '../../../ui/Avatar/avatar';
import CheckList from './checkboxListGroups';
import Modal from '../../../ui/Modal/simpleModal';
// UI imports
import * as s from './style';

// Component
const Subscription = () => {
  const { user, selectedGroups } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const data = JSON.parse(user);
  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`Home · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.wrapper >
        <s.homeContainer>
          <s.Container>
            <s.Icon src={`${URL_WEB}/images/codeimg.png`}></s.Icon>
          </s.Container>
        <s.authContainer>
        <s.userProfile>
         <Avatar name={data.name} avatar={data.avatar} />
         <s.textProfile>
           <s.nameText>{data.name}</s.nameText>
           <s.emailText>{data.email}</s.emailText>
         </s.textProfile>
        </s.userProfile>
        <s.description>
          <s.descriptionText>Select a group</s.descriptionText>
          <s.descriptionText2>We recommend you have at least Report privileges in your Gitlab group to proceed</s.descriptionText2>
        </s.description>
        <s.listContainer>
        <CheckList />
        </s.listContainer>
        </s.authContainer>
        <s.footerText>
          By signing up to Konpurito you agree to our <span onClick={handleOpen} style={{ cursor: 'pointer', color: '#EB8E21', textDecoration: 'underline' }}>
             terms of service</span>
        </s.footerText>
        </s.homeContainer>
        <Modal isOpen={openModal} handleClose={handleClose} />
      </s.wrapper>

      {/* check user already logged in */}

      {/* TODO IMPRVE AUTH CHECK */}
      <GroupsCheck />
    </>
  );
};

export default Subscription;
