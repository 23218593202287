import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '../Avatar/avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: '100%',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function Chips({ name, avatar, handleClick }) {
  const classes = useStyles();

  const style = {
    height: '56px', width: '335px', background: '#F2F2F2', border: '1px solid #DDDDDD', borderRadius: '100px',
  };

  const textPrimary = {
    textTransform: 'capitalize', fontStyle: 'nomarl', fontWeight: 'bold', fontSize: '16px', lineHeight: '24px', color: '#263742',
  };

  const setLabel = (name, desc) => {
    return (
          <div>
              <p style={{ ...textPrimary }}>{name}</p>
              <p>{desc}</p>
          </div>
    );
  };

  return (
    <div className={classes.root}>
      <Chip style={{ ...style }}
      avatar={<Avatar avatar={avatar}></Avatar>} label={setLabel(name)} onClick={handleClick} />
    </div>
  );
}
