// Imports
import isEmpty from 'lodash/isEmpty';

// App Imports
import {
  SET_USER,
  LOGOUT,
  SET_TOKEN,
  SET_GROUPS,
  SET_PROJECTS,
  SET_ISSUES,
  SET_ACTIVITY,
  SET_SELECTED_GROUPS,
  SET_SECTION,
  SET_MEMBERS,
  SET_AUTH,
} from '../actions/types';

// Auth (user)

// Initial State

export const authInitialState = {
  auth: '',
  isAuthenticated: false,
  user: null,
  groups: [],
  selectedGroups: [],
  projects: [],
  issues: [],
  activity: [],
  members: [],
  token: null,
  section: 'home',
};

// State
export default (state = authInitialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: action.user,
      };

    case SET_AUTH:
      return {
        ...state,
        auth: action.auth,
      };

    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case SET_GROUPS:
      return {
        ...state,
        groups: action.groups,
      };

    case SET_SELECTED_GROUPS:
      return {
        ...state,
        selectedGroups: action.selectedGroups,
      };

    case SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };

    case SET_ISSUES:
      return {
        ...state,
        issues: action.issues,
      };

    case SET_ACTIVITY:
      return {
        ...state,
        activity: action.activity,
      };

    case SET_MEMBERS:
      return {
        ...state,
        members: action.members,
      };

    case SET_SECTION:
      return {
        ...state,
        section: action.section,
      };

    case LOGOUT:
      return authInitialState;

    default:
      return state;
  }
};
