import dotenv from 'dotenv';

// Load .env
dotenv.config({ path: '.env.local' });
// STRIPE
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_PK;
// URL
export const URL_API = process.env.REACT_APP_URL_API;
export const URL_WEB = process.env.REACT_APP_URL_WEB;

// Oauth
export const OAUTH_GITLAB_ID = process.env.REACT_APP_OAUTH_GITLAB_ID;
