// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function register(user, groups, members, stripedata) {
  // eslint-disable-next-line no-console
  console.log(user, groups, members, stripedata, 'STRIPE AXIOS');
  return axios.get(`${URL_API}/user/register`, {
    params: { userData: user, groupsData: groups, membersData: members, stripe: stripedata },
  });
}
