import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Modal from '@material-ui/core/Modal';
import { URL_WEB } from '../../setup/config/env';
import { contactUs } from '../../modules/user/api/actions/query';
import 'boxicons';
import './style.scss';

const style = ({
  display: 'grid',
  justifyItems: 'center',
  alignItems: 'center',
  padding: '3rem',
});

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2rem 1.5rem',
    borderRadius: '10px',
    boxSizing: 'border-box',
    zindex: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

export default function SimpleModal({ isOpen, handleClose }) {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [mailSended, setMailSended] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setMailSended(false);
  }, []);

  const sendEmail = async () => {
    const data = await contactUs(formData);
    if (data.data.result.data) {
      setMailSended(true);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!formData.name) {
      // eslint-disable-next-line no-alert
      alert('Please enter your name');
    } else if (!formData.email) {
      // eslint-disable-next-line no-alert
      alert('Please enter your email');
    } else if (!formData.message) {
      // eslint-disable-next-line no-alert
      alert('Please enter message');
    } else {
      sendEmail();
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    }
  };

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const body = (
    <div className={classes.paper}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Avatar src={`${URL_WEB}/icons/gitDone.png`}></Avatar>
        </div>
    { !mailSended && (
        <>
        <h2>CONTACT US</h2>
        <form onSubmit={handleSubmit}>
        <input
        type="text"
        name="name"
        placeholder="Name"
        onChange={updateInput}
        value={formData.name}
        />
        <input
        type="email"
        name="email"
        placeholder="Email"
        onChange={updateInput}
        value={formData.email}
        />
        <textarea
        type="text"
        name="message"
        placeholder="Message"
        onChange={updateInput}
        value={formData.message}
        ></textarea>
        <button type="submit">Submit</button>
    </form>
    </>
    )}
       { mailSended && (
           <>
            <h2>Message sent!</h2>
            <h3>We will contact you as soon as possible!</h3>
           <div style={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
           <box-icon name='check-circle' type='solid' animation='tada' color='#03862c' size='80px' ></box-icon>
           </div>
        </>
       )}

      <SimpleModal />
    </div>
  );

  return (
    <div>
      <Modal
        style={{ ...style }}
        open={isOpen}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </div>
  );
}
