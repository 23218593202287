// Imports
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import StickyHeadTable from '../../../ui/Charts/table';
import { setSection } from '../api/actions/query';

// UI imports
import * as s from './groups.styles';

// App imports
import params from '../../../setup/config/params';

const columns = [
  {
    id: 'name',
    label: 'Group Name',
    backgroundColor: '#008000',
    align: 'start',
  },
  { id: 'date', label: 'Date Created', minWidth: 100, align: 'center' },
  {
    id: 'url',
    label: '',
    minWidth: 100,
    align: 'center',
  },
];

// Component
const Groups = () => {
  const { selectedGroups } = useSelector((state) => state.auth);

  const groupsTable = selectedGroups.map((group) => ({
    name: group.name,
    date: moment(group.created_at).format('ll'),
    url: group.url,
  }));

  // on load
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSection('groups'));
  }, []);

  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`User Groups · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.DashboardWrapper>
        <s.DashboardContentContainer>
          <s.DashboardContentTable>
            <StickyHeadTable
              columns={columns}
              rows={groupsTable}
              tableTitle={'My groups'}
            ></StickyHeadTable>
          </s.DashboardContentTable>
        </s.DashboardContentContainer>
      </s.DashboardWrapper>
    </>
  );
};

export default Groups;
