import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Redirect } from 'react-router-dom';
import Button from '../../../ui/Button/CustomButton';
import { setSelectedGroups, setAuth } from '../../user/api/actions/query';
import routes from '../../../setup/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    maxWidth: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 300,
    maxHeight: 300,
  },
  button: {
    display: 'grid',
    marginTop: '15px',
    justifyContent: 'center',
  },
}));

export default function CheckboxList() {
  const { groups, groupsSelected } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClick = () => {
    dispatch(setSelectedGroups(checked));
    window.sessionStorage.setItem('groups', JSON.stringify(checked));
    window.sessionStorage.setItem('auth', 'members');
    dispatch(setAuth('members'));
    return groupsSelected ? <Redirect to={routes.PagesSubscription2.path} /> : '';
  };

  return (
    <>
    <List dense className={classes.root}>
      {groups.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value.id}`;
        return (
          <ListItem style={{ marginBottom: '15px' }} key={value.id} button>
            <ListItemAvatar>
              <Avatar
                alt={`${value.name}`}
                src={`${value.avatar}`}
              />
            </ListItemAvatar>
            <ListItemText style={{ textTransform: 'capitalize', color: '#8B9096' }} id={labelId} primary={`${value.name}`} secondary={`${value.level}`}/>
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(value)}
                checked={checked.indexOf(value) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
    <div className={classes.button}>
      <Button title={checked.length === 0 ? 'Select a group' : 'Continue'} disable={checked.length === 0} onClick={handleClick} />
    </div>
  </>
  );
}
