import styled from '@emotion/styled';

export const SidebarWrapperr = styled.div`
  display: grid;
`;

export const SidebarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .1fr .8fr .1fr;
  justify-items: center;
  height: 100vh;
  max-width: 280px;
  min-width: 80px;
  Background-color: white;
  border-right: solid 1px #dddddd;
  align-items: center;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.25);
`;

export const MenuContainer = styled.div`
  display: grid;
  height: 100%;
  Background-color: white;
  padding: 40px 8px;
  justify-items: center;
`;

export const MenuItems = styled.div`
  display: grid;
  width: 40px;
  height: 40px; 
  border: 2px solid #F08421; 
  border-radius: 50%;
  align-items: center;
  justify-items: center;
`;

export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 245px 1fr;
  border-right: solid 1px #dddddd;
`;

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 0.2fr 0.6fr .2fr .2fr 1fr;
  height: 100vh;
  width: 246px;
  background-color: white;
  border-right: solid 1px #dddddd;
  
`;

export const profileD = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.4fr 0.2fr 0.6fr;
  padding: 24px;
  border-bottom: 1px solid #dddddd;
`;

export const overviewD = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #dddddd;
`;

export const overviewText = styled.div`
  margin-left: 24px;
  color: #8b9096;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s ease-in all;
  &:hover {
    color: #f08421;
    transition: 0.1s ease-in all;
  }
`;

export const projectD = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #dddddd;
`;

export const projectText = styled.div`
  margin-left: 16px;
  color: #8b9096;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s ease-in all;
  &:hover {
    color: #f08421;
    transition: 0.1s ease-in all;
  }
`;

export const accountD = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
`;

export const profile = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
`;

export const accountText = styled.div`
  margin-left: 24px;
  color: #8b9096;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s ease-in all;
  &:hover {
    color: #f08421;
    transition: 0.1s ease-in all;
  }
`;

export const profileSub1 = styled.div`
  font-weight: 900;
  font-size: 10px;
  margin-bottom: 8px;
`;

export const profileSub2 = styled.div`
  margin-bottom: 4px;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
`;

export const profileSub3 = styled.div`
  margin: 0px;
  color: #8B9096;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
`;
