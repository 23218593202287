
// App Imports
import { SET_SECTION } from '../types';

export default function setSection(section) {
  return (dispatch) => {
    dispatch({
      type: SET_SECTION, section,
    });
  };
}
