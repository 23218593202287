// Imports
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import {
  projects,
  userLabels,
  setRefreshToken,
  userIssues,
  allIssues,
  setSection,
} from '../../api/actions/query';
import Gantt from '../Gantt/Scheduler';

// UI imports
import * as s from './report.styles';
import './style.css';
import Select from '../../../../ui/Input/CustomSelect';
import BottonNavigation from '../../../../ui/Button/SimpleBootonNavigation';
import 'boxicons';
import PieChart from '../../../../ui/Charts/pieChart';
import Table from '../../../../ui/Charts/table';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#F08421',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F17300',
      color: 'white',
    },
  },
}))(Button);

const styles = {
  app: {
    justifyItems: 'center',
    alignItems: 'center',
    display: 'grid',
    height: '100%',
    fontFamily: 'Lato',
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: 10,
  },
  back: {
    marginTop: 200,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h4: {
    color: 'grey',
  },
  load: {
    color: 'grey',
    marginLeft: '28px',
  },
  icon: {
    marginLeft: 40,
  },
};

const columnsLastActivities = [
  {
    id: 'title',
    label: 'Name',
  },
  { id: 'author', label: 'Author' },
  {
    id: 'label',
    label: 'Label',
  },
];

// Component
const Report = ({ title }) => {
  // state
  const { selectedGroups, token, members } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const data = JSON.parse(user);
  const dispatch = useDispatch();

  const listGroups = selectedGroups.map((groups) => ({
    value: groups.groupId,
    label: groups.name,
  }));

  const [isLoading, isLoadingToggle] = useState(false);
  const [project, setProject] = useState(null);
  const [projectlist, setProjectlist] = useState(null);
  const [labellist, setLabellist] = useState(null);
  const [resourcesList, setResourcesList] = useState(null);
  const [eventList, setEventList] = useState(null);
  const [issuesByDev, setIssuesByDev] = useState(null);
  const [issuesByStatus, setIssuesByStatus] = useState(null);
  const [columnsLabels, setColumnsLabels] = useState(null);
  const [valueNav, setValueNav] = useState(false);

  useEffect(() => {
    if (project) {
      // eslint-disable-next-line no-use-before-define
      fetchlabels(project);
    }
  }, [project]);

  useEffect(() => {
    dispatch(setSection('report'));
  }, []);

  const fetchProject = async (group) => {
    const groupId = group.value;
    isLoadingToggle(true);

    try {
      const data = await projects(groupId, token);
      if (data.data) {
        const listProjects = data.data.result.projects.map((projects) => ({
          value: projects.id,
          label: projects.name,
          avatar: projects.avatar_url,
          url: projects.web_url,
        }));
        setProjectlist(listProjects);
        dispatch(setRefreshToken(data.data.result.refresh_token));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      isLoadingToggle(false);
    }
  };

  const fetchlabels = async (project) => {
    const projectId = project.value;
    isLoadingToggle(true);

    try {
      const data = await userLabels(projectId, token);
      if (data.data) {
        const listLabels = data.data.result.labels.map((labels) => ({
          value: labels.id,
          label: labels.name,
          color: labels.color,
        }));
        setLabellist(listLabels);
        const colLabels = listLabels.map((item) => ({
          id: item.label,
          label: item.label,
        }));
        colLabels.unshift({ id: 'name', label: 'Name' });
        setColumnsLabels(colLabels);
        const refreshToken = data.data.result.refresh_token;
        dispatch(setRefreshToken(refreshToken));
        // eslint-disable-next-line no-use-before-define
        fetchAllIssues(project, refreshToken, listLabels);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      isLoadingToggle(false);
    }
  };

  const fetchIssues = async (project, label) => {
    const projectId = project.value;
    const labelId = label.label;

    isLoadingToggle(true);

    try {
      const data = await userIssues(projectId, labelId, token);

      if (data.data) {
        const resources = data.data.result.issues.map((issues) => ({
          id: issues.id.toString(),
          name: issues.title,
          author: issues.assignee.name,
        }));
        const events = data.data.issues.map((issues) => ({
          id: issues.id,
          start: issues.created_at,
          end: issues.updated_at,
          title: issues.title,
          resourceId: issues.id.toString(),
          author: issues.assignee.name,
          label: label.label,
          img: issues.assignee.avatar_url,
          bgColor: label.color,
        }));
        setResourcesList(resources);
        setEventList(events);
        dispatch(setRefreshToken(data.data.result.refresh_token));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      isLoadingToggle(false);
    }
  };

  const fetchAllIssues = async (project, refreshToken, listLabels) => {
    const projectId = project.value;
    isLoadingToggle(true);

    try {
      const data = await allIssues(projectId, refreshToken);

      if (data.data) {
        const resources = data.data.result.issues.map((issues) => ({
          id: issues.id.toString(),
          name: issues.title,
          author: issues?.assignee?.name,
          authorId: issues?.assignee?.id,
          issue: `#${issues.iid}`,
          bgColor: listLabels.filter((m) => m.label === issues.labels[0])[0]
            ?.color,
        }));

        const assignee = data.data.result.issues.map((issues) => ({
          name: issues.assignee?.name,
          label: issues.labels[0],
        }));

        const assigneeFilter = assignee.filter(({ name: id1 }) => members.some(({ name: id2 }) => id2 === id1));

        const countIssuesByDev = {};
        assigneeFilter.forEach((i) => {
          countIssuesByDev[i.name] = (countIssuesByDev[i.name] || 0) + 1;
        });
        setIssuesByDev(countIssuesByDev);

        const output = [];

        assigneeFilter.forEach((item) => {
          const existing = output.filter((v, i) => {
            return v.name === item.name;
          });

          if (existing.length) {
            const existingIndex = output.indexOf(existing[0]);
            output[existingIndex].label = output[existingIndex].label.concat(
              item.label,
            );
          } else {
            item.label = [item.label];
            output.push(item);
          }
        });

        const counter = [];

        output.forEach((obj) => {
          [obj].forEach((m) => {
            const count = {};
            m.label.forEach((i) => {
              count[i] = (count[i] || 0) + 1;
            });
            m.label = count;
            m.label.name = m.name;
            counter.push(m.label);
          });
        });
        setIssuesByStatus(counter);

        const events = data.data.result.issues.map((issues) => ({
          id: issues.id,
          start: issues.created_at,
          end: issues.updated_at,
          title: issues.title,
          resourceId: issues.id.toString(),
          author: issues.assignee?.name,
          authorId: issues?.assignee?.id,
          img: issues.assignee?.avatar_url,
          label: listLabels.filter((m) => m.label === issues.labels[0])[0]
            ?.label,
          bgColor: listLabels.filter((m) => m.label === issues.labels[0])[0]
            ?.color,
        }));

        const filterResources = resources?.filter(({ author: id1 }) => members.some(({ name: id2 }) => id2 === id1));
        const filterEvents = events?.filter(({ author: id1 }) => members.some(({ name: id2 }) => id2 === id1));

        setResourcesList(filterResources);
        setEventList(filterEvents);
        dispatch(setRefreshToken(data.data.result.refresh_token));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      isLoadingToggle(false);
    }
  };

  const onChangeGroup = (value) => {
    fetchProject(value);
  };
  const onChangeProject = (value) => {
    setProject(value);
  };
  const onChangeLabel = (value) => {
    fetchIssues(project, value);
  };

  const changeValue = () => {
    if (valueNav) {
      setValueNav(false);
    } else {
      setValueNav(true);
    }
  };

  const printPDF = () => {
    const domElement = document.getElementById('RBS-Scheduler-root');
    html2canvas(domElement).then((canvas) => {
      const img = canvas.toDataURL('image/png');
      // eslint-disable-next-line new-cap
      const pdf = new jsPdf('p', 'mm', 'a4');
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      pdf.addImage(img, 'JPEG', 0, 0, width, height);
      pdf.save('reportGantt.pdf');
    });
  };

  return (
    <s.DashboardWrapper>
      <s.DashboardContainer>
      <s.MenuContainer>
      <div style={styles.app}>
            <Select
              style={{ fontSize: '16px', textTransform: 'capitalize' }}
              onChange={onChangeGroup}
              options={listGroups}
              placeholder='Select a Group'
            />
          </div>
          <div style={styles.app}>
            <Select
              style={{ fontSize: '16px' }}
              onChange={onChangeProject}
              options={projectlist}
              placeholder='Select a project'
            />
          </div>
          <div style={styles.app}>
            <Select
              style={{ fontSize: '16px' }}
              onChange={onChangeLabel}
              options={labellist}
              placeholder='Filter by label'
            />
          </div>
          <div style={styles.app}>
            {eventList && !isLoading && (
              <ColorButton onClick={printPDF}>Download</ColorButton>
            )}
          </div>
      </s.MenuContainer>
      <s.NavContainer>
       { eventList && !isLoading && (
          <BottonNavigation
              value={valueNav}
              changeValue={changeValue}
          ></BottonNavigation>
       )}
      </s.NavContainer>
      <s.MainContainer>
      {!eventList && !isLoading && (
          <div style={styles.back}>
            <div style={styles.icon}>
              <box-icon color='orange' size='lg' name='line-chart'></box-icon>
            </div>
            <h4 style={styles.h4}>{`Select a ${
              projectlist ? 'project' : 'group'
            }`}</h4>
          </div>
      )}
      {!eventList && isLoading && (
          <div style={styles.back}>
            <div style={styles.icon}>
              <box-icon
                color='orange'
                size='lg'
                name='loader'
                animation='spin'
              ></box-icon>
            </div>
            <h4 style={styles.load}>Loading...</h4>
          </div>
      )}
      {eventList && !isLoading && !valueNav && (
              <div>
                <Gantt
                  resources={resourcesList}
                  events={eventList}
                  project={project}
                ></Gantt>
              </div>
      )}
      {eventList && !isLoading && valueNav && (
               <div>
               <div className={'grid-container'}>
                 <PieChart
                   issuesByDev={issuesByDev}
                   projectName={project}
                 ></PieChart>
                 <div className={'grid-item'}>
                   <Table
                     columns={columnsLabels}
                     rows={issuesByStatus}
                     tableTitle={'Assignments by status'}
                   ></Table>
                 </div>
               </div>
               <div className={'grid-container-footer'}>
                 <Table
                   columns={columnsLastActivities}
                   rows={eventList}
                   tableTitle={'Created issues'}
                 ></Table>
               </div>
             </div>
      )}
      </s.MainContainer>
        </s.DashboardContainer>
    </s.DashboardWrapper>
  );
};

export default Report;
