// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function cancelSubscription(user, customer) {
  return axios.get(`${URL_API}/stripe/cancel`, {
    params: { userData: user, stripeCustomer: customer },
  });
}
