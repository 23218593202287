// Imports
import axios from 'axios';

// App Imports
import { LOGOUT } from '../types';
import { logoutUnsetUserLocalStorage } from '.';

// Log out user and remove token from localStorage
export default function logout() {
  return (dispatch) => {
    logoutUnsetUserLocalStorage();

    delete axios.defaults.headers.common.Authorization;

    dispatch({
      type: LOGOUT,
    });

    dispatch({
      type: 'RESET',
    });

    // Clear cache
    for (const key in localStorage) {
      if (key.indexOf('CACHE.KEY/') !== -1) {
        window.localStorage.removeItem(key);
      }
    }
  };
}
