
// App Imports
import { SET_SELECTED_GROUPS } from '../types';

export default function setSelectedGroups(selectedGroups) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_GROUPS, selectedGroups,
    });
  };
}
