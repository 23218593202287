import styled from '@emotion/styled';

export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 30px;
`;

export const DashboardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 860px;
  overflow-y: scroll;
  background-color: #F9F9F9;
`;

export const DashboardContentHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding-bottom: 30px;
`;

export const DashboardContentTable = styled.div`
  margin: 24px;
`;
