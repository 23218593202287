import styled from '@emotion/styled';

export const DashboardWrapper = styled.div`
  overflow-y: scroll;
`;

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-rows: 112px 55px .7fr;
`;

export const MenuContainer = styled.div`
display: grid;
grid-template-columns: repeat(4, 1fr);
grid-column-gap: 24px;
background-color: #F1F1F1;
border: 1px solid #DDDDDD;
padding-left: 24px;
`;

export const NavContainer = styled.div`
  height: 55px;
  max-width: 100%;
`;

export const MainContainer = styled.div`
  
`;

