// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function userIssues(projectId, labelId, refreshToken) {
  return axios.get(`${URL_API}/gitlab/userIssues`, {
    params: { id: projectId, label: labelId, token: refreshToken },
  });
}
