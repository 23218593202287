// Imports
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// App imports
import Avatar from '@material-ui/core/Avatar';
import { URL_WEB } from '../../../setup/config/env';
import params from '../../../setup/config/params';
import AuthCheck from '../../common/AuthCheck';
import GroupsCheck from '../../common/AuthCheck/checkGroups';
import SubsCheck from '../../common/AuthCheck/subscriptionCheck';
import gitlab from '../../../setup/oauth/gitlab';

// UI imports
import Button from '../../../ui/Button';
import Modal from '../../../ui/Modal/simpleModal';
import Alert from '../../../ui/Alert/alert';
// UI imports
import * as s from './style';

// Component
const Home = () => {
  const [agree, setAgree] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const closeWarning = () => {
    setOpenAlert(false);
  };

  const showWarning = () => {
    setOpenAlert(true);
  };

  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`Home · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.wrapper >
        <s.homeContainer>
        <s.wrapperText>
          <s.Container>
            <Avatar src={`${URL_WEB}/icons/gitDoneWhite.png`}></Avatar>
            <s.welcomeText>{`Welcome to ${params.site.name}`}</s.welcomeText>
          </s.Container>
        </s.wrapperText>
        <s.authContainer>
          <s.textDescriptionTitle>Welcome</s.textDescriptionTitle>
          <s.textDescription>Sign in with your gitlab account and authorize GitDone to access to your groups</s.textDescription>
          <s.buttonContainer>
            {/* tech */}
            <a href={agree ? gitlab() : null}>
              <Button
                title='Sign in with Gitlab'
                image={`${URL_WEB}/images/tech/gitlab.svg`}
                onClick={ !agree ? showWarning : null}
              />
            </a>
            <Alert isOpen={openAlert} handleClose={closeWarning}
            title={'To Continue'}
            description={'Please indicate that you have read and agree to the Terms and Conditions.'}/>
          </s.buttonContainer>
          <s.termsText>
          <input type="checkbox" id="agree" onChange={checkboxHandler} />
          <label htmlFor="agree"> I agree to <b onClick={handleOpen} style={{ cursor: 'pointer' }} >terms and conditions</b></label>
          </s.termsText>
        </s.authContainer>
        <s.footerText>
          By signing up to GitDone you agree to our <span onClick={handleOpen} style={{ cursor: 'pointer', color: '#EB8E21', textDecoration: 'underline' }}>
             terms of service</span>
        </s.footerText>
        </s.homeContainer>
        <Modal isOpen={openModal} handleClose={handleClose} />
      </s.wrapper>

      {/* check user already logged in */}

      {/* TODO IMPROVE AUTH CHECK */}
      {/* <SubsCheck />
      <AuthCheck />
      <GroupsCheck /> */}
    </>
  );
};

export default Home;
