// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function groupMebers(groupId, refreshToken) {
  return axios.get(`${URL_API}/gitlab/groupMembers`, {
    params: { id: groupId, token: refreshToken },
  });
}
