// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function userLabels(projectId, refreshToken) {
  return axios.get(`${URL_API}/gitlab/userLabels`, {
    params: { id: projectId, token: refreshToken },
  });
}
