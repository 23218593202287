// Imports
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App imports
import { URL_WEB } from '../../../setup/config/env';
import params from '../../../setup/config/params';
import Chip from '../../../ui/Chip/chip';
import CheckboxList from './checkboxListMembers';
import Modal from '../../../ui/Modal/simpleModal';

// UI imports
import * as s from './style';

// Component
const Subscription = () => {
  const { selectedGroups } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const goBack = () => {
    history.goBack();
  };

  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`Home · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.wrapper >
        <s.homeContainer>
          <s.Container>
            <s.Icon src={`${URL_WEB}/images/codeimg.png`}></s.Icon>
          </s.Container>
        <s.authContainer>
        <s.memberDescription>
         <s.textProfile>
           <s.textPrimary>Manage your Teams</s.textPrimary>
           <s.textSecondary>Select your developers per group</s.textSecondary>
         </s.textProfile>
        </s.memberDescription>
        <s.description>
          <Chip name={selectedGroups[0].name} avatar={selectedGroups[0].avatar} handleClick={goBack}/>
        </s.description>
        <s.listContainer>
        <CheckboxList />
        </s.listContainer>
        </s.authContainer>
        <s.footerText>
          By signing up to Konpurito you agree to our <span onClick={handleOpen} style={{ cursor: 'pointer', color: '#EB8E21', textDecoration: 'underline' }}>
             terms of service</span>
        </s.footerText>
        </s.homeContainer>
        <Modal isOpen={openModal} handleClose={handleClose} />
      </s.wrapper>

      {/* check user already logged in */}

    </>
  );
};

export default Subscription;
