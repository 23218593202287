
// App Imports
import { SET_MEMBERS } from '../types';

export default function setMembers(members) {
  return (dispatch) => {
    dispatch({
      type: SET_MEMBERS, members,
    });
  };
}
