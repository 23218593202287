// App imports
import PagesHome from '../modules/pages/Home';
import GroupSelect from '../modules/pages/GroupsSelect';
import MemberSelect from '../modules/pages/MembersSelect';
import PagesAuthorize from '../modules/pages/Authorize';
import UserDashboard from '../modules/user/Dashboard';
import UserProfile from '../modules/user/Profile';
import UserData from '../modules/user/UserReport';
import UserProjects from '../modules/user/Projects';
import UserGroups from '../modules/user/Groups';
import Checkout from '../modules/pages/Checkout';
import Cancel from '../modules/pages/Cancel';
import TermsOfService from '../modules/pages/TermsOfService';
import PrivacyPolicy from '../modules/pages/PrivacyPolicy';

// Combined routes
const routes = {
  // pages home
  pagesHome: {
    path: '/',
    component: PagesHome,
    exact: true,
  },

  // pages authorize
  pagesAuthorize: {
    path: '/authorize',
    component: PagesAuthorize,
  },

  // user dashboard
  userDashboard: {
    path: '/dashboard',
    component: UserDashboard,
    auth: true,
  },

  PagesSubscription: {
    path: '/subscription',
    component: GroupSelect,
    auth: true,
  },

  PagesSubscription2: {
    path: '/subscription2',
    component: MemberSelect,
    auth: true,
  },

  TermsOfService: {
    path: '/TermsOfService',
    component: TermsOfService,
  },

  PrivacyPolicy: {
    path: '/PrivacyPolicy',
    component: PrivacyPolicy,
  },

  Cancel: {
    path: '/cancel',
    component: Cancel,
  },

  Checkout: {
    path: '/checkout',
    component: Checkout,
  },
  // user profile
  userData: {
    path: '/data',
    component: UserData,
    auth: true,
  },
  // user projects
  userProjects: {
    path: '/projects',
    component: UserProjects,
    auth: true,
  },
  // user projects
  userGroups: {
    path: '/groups',
    component: UserGroups,
    auth: true,
  },

  // user profile
  userProfile: {
    path: '/profile',
    component: UserProfile,
    auth: true,
  },
};

export default routes;
