// Params
export default {
  site: {
    name: 'GitDone',
    description: 'GitDone description',
    author: 'Alioit',
    url: 'https://alioIt.com',
    copyright: '© 2020',
  },

  // common
  common: {
    date: 'YYYY-MM-DD hh:mm a',
  },

  // user
  user: {
    oauth: {
      providers: {
        gitlab: {
          key: 'gitlab',
          title: 'Gitlab',
        },
      },
    },
  },
};
