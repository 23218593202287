// Actions Types

// Auth
export const SET_USER = 'AUTH/SET_USER';
export const LOGOUT = 'AUTH/LOGOUT';
export const SET_TOKEN = 'AUTH/SET_TOKEN';
export const SET_GROUPS = 'AUTH/SET_GROUPS';
export const SET_SELECTED_GROUPS = 'AUTH/SET_SELECTED_GROUPS';
export const SET_PROJECTS = 'AUTH/SET_PROJECTS';
export const SET_ISSUES = 'AUTH/SET_ISSUES';
export const SET_ACTIVITY = 'AUTH/SET_ACTIVITY';
export const SET_SECTION = 'AUTH/SET_SECTION';
export const SET_MEMBERS = 'AUTH/SET_MEMBERS';
export const SET_AUTH = 'AUTH/SET_AUTH';

