import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import * as s from './styles';
import { URL_WEB } from '../../../setup/config/env';
import Menu from './menu';
import 'boxicons';

const Sidebar = props => {
  const {
    user = '',
    isSidebarOpen = true,
  } = props;

  const menuStyle = isSidebarOpen ? { borderRadius: '0px', width: '100%' } : { borderRadius: '0px', width: '100%' };

  return (
    <s.SidebarWrapperr style={{ gridTemplateColumns: isSidebarOpen ? '.2fr .8fr' : '.8fr' }}>
    <s.SidebarContainer>
      <Avatar alt="GitDone" src={`${URL_WEB}/icons/gitDone.png`} />
      <s.MenuContainer style={ menuStyle }>
        <s.MenuItems>
        <Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
        <Avatar alt="Home" src={`${URL_WEB}/icons/home2.svg`} style={{ width: '25px', height: '25px' }} />
        </ Link>
        </s.MenuItems>
      </s.MenuContainer>
      <Link to={'/profile'} style={{ textDecoration: 'none' }}>
      <Avatar alt="User" src={user?.avatar} />
      </Link>
    </s.SidebarContainer>
    {isSidebarOpen && (
        <Menu></Menu>
    )}
    </s.SidebarWrapperr>
  );
};

export default Sidebar;
