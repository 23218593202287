// Imports
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { setSection, userData, setMembers, setSelectedGroups, projectsByGroups, setProjects, setRefreshToken } from '../api/actions/query';

// UI imports
import * as s from './Dashboard.styles';
import 'boxicons';
import StickyHeadTable from '../../../ui/Charts/table';
import ContactUs from '../../../ui/Modal/contactUs';

// App imports
import params from '../../../setup/config/params';

const columns = [
  {
    id: 'title',
    label: 'Title',
    color: '#8B9096',
    align: 'start',
  },
  {
    id: 'author',
    label: 'Author',
    backgroundColor: '#008000',
    minWidth: 170,
    align: 'center',
  },
  { id: 'date', label: 'Date', minWidth: 100, align: 'center' },
  {
    id: 'url',
    label: '',
    minWidth: 100,
    align: 'center',
  },
];

// Component
const Dashboard = () => {
  // state
  const { user, projects, issues, activity, selectedGroups, members, token } = useSelector(
    (state) => state.auth,
  );

  const [openModal, setOpenModal] = useState(false);
  const [numberProjects, setNumberProjects] = useState('-');
  const userFormatted = JSON.parse(user);

  const getProjectNumber = (projectSelected) => {
    let counter = 0;

    projectSelected.forEach(element => {
      counter += element.length;
    });
    setNumberProjects(counter);
  };

  const numberGroups = Object.keys(selectedGroups).length;
  // const numberProjects = getProjectNumber();
  const numberIssues = Object.keys(issues).length;

  const dispatch = useDispatch();

  const getProjectsSelected = async (groups) => {
    // eslint-disable-next-line no-console
    console.log(groups, token, 'projectsbyGroup');
    const tokenData = window.localStorage.getItem('token');
    const data = await projectsByGroups(groups, token || tokenData);

    const projectSelected = data.data.result.projects;
    const refreshToken = data.data.result.refresh_token;
    dispatch(setProjects(projectSelected));
    dispatch(setRefreshToken(refreshToken));
    getProjectNumber(projectSelected);
  };

  const getUserData = async () => {
    const data = await userData(userFormatted.id);
    dispatch(setMembers(data.data?.result.data.membersSelected));
    dispatch(setSelectedGroups(data.data.result.data.groupsSelected));
    getProjectsSelected(data.data.result.data.groupsSelected);
  };

  useEffect(() => {
    dispatch(setSection('home'));
    getUserData();
  }, []);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const filterActivity = activity.filter(({ author: id1 }) => members.some(({ name: id2 }) => id2 === id1));

  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`User Dashboard · ${params.site.name}`}</title>
      </Helmet>
      {/* content */}
        <s.DashboardContentContainer>
          <s.DashboardContentHeader>
            <s.DashboardContentHeaderIcons>
              <s.DashboardContenticon>
                <s.DashboardContenticonText>
                  {numberGroups}
                </s.DashboardContenticonText>
                <s.DashboardContenticonText2>
                  Groups
                </s.DashboardContenticonText2>
              </s.DashboardContenticon>
              <s.DashboardContenticon>
                <s.DashboardContenticonText>
                  {numberProjects}
                </s.DashboardContenticonText>
                <s.DashboardContenticonText2>
                  Projects
                </s.DashboardContenticonText2>
              </s.DashboardContenticon>
              <s.DashboardContenticon>
                <s.DashboardContenticonText>
                  {numberIssues}
                </s.DashboardContenticonText>
                <s.DashboardContenticonText2>
                  Issues assigned to me
                </s.DashboardContenticonText2>
              </s.DashboardContenticon>
            </s.DashboardContentHeaderIcons>
          </s.DashboardContentHeader>
          <s.DashboardTable>
            <StickyHeadTable
              columns={columns}
              rows={filterActivity}
              tableTitle={'Recent Activity'}
              onClick={handleOpen}
            ></StickyHeadTable>
          </s.DashboardTable>
          <ContactUs isOpen={openModal} handleClose={handleClose}/>
        </s.DashboardContentContainer>
    </>
  );
};

export default Dashboard;
