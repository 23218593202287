/* eslint-disable no-unused-expressions */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CustomButton from '../Button/CustomButton.js';

const useStyles = makeStyles({
  root: {
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    borderRadius: '20px',
    padding: '30px',
  },
  container: {
    maxHeight: '100%',
  },
});

export default function StickyHeadTable({ columns, rows, tableTitle, onClick }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  rows.forEach((element) => {
    element.name = element.name ? element.name : 'No Assignee';
  });

  return (
    <Paper elevation={0} className={classes.root}>
      <h3
        style={{
          fontFamily: 'Lato',
          fontSize: 16,
          paddingTop: 0,
          paddingLeft: 0,
          paddingBottom: 24,
          textAlign: 'left',
          color: '#263742',
        }}
      >
        {tableTitle}
      </h3>
      {
        rows.length > 0 && (
          <div>
          <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={Math.random()}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: 'F9F9F9',
                    borderTop: '1px solid #DDDDDD',
                    fontSize: '16px',
                    color: '#8B9096',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={Math.random()}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={Math.random()}
                          align={column.align}
                        >
                          {column.id === 'url' ? (
                            <CustomButton
                              title={'Open in gitlab'}
                              type={'url'}
                              src={value}
                            />
                          ) : (
                            value || '0'
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </div>
        )
      }
      { rows.length <= 0 && (
        <div>
        <h4 style={{ margin: '20px', fontStyle: 'italic' }}>
          You do not have recent activities on master or you do not have sufficient access permission.
          </h4>
          <h4 onClick={onClick} style={{ margin: '20px', color: '#EB8E21', cursor: 'pointer' }}>Please contact support</h4>
        </div>
      )}
    </Paper>
  );
}
