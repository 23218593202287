import styled from '@emotion/styled';

export const DashboardContainer = styled.div`
  height: 776px;
  width: 296px;
  background-color: #F9F9F9;
  box-shadow: 10px 10px 20px rgba(200, 180, 156, 0.14);
  border-radius: 0px 20px 20px 0px;
`;

export const Dashboard = styled.div`
  padding-left: 24px;
  padding-top: 32px;
  padding-right: 24px;
`;

