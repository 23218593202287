
// App Imports
import { SET_ISSUES } from '../types';

export default function setGroups(issues) {
  return (dispatch) => {
    dispatch({
      type: SET_ISSUES, issues,
    });
  };
}
