// Imports
import React from 'react';
import { Helmet } from 'react-helmet';
import 'boxicons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Button from '../../../ui/Button/CustomButton';
import routes from '../../../setup/routes';
import 'react-toastify/dist/ReactToastify.css';

import params from '../../../setup/config/params';
import * as s from './style';
// UI imports
toast.configure();
// Component

const Cancel = () => {
  const history = useHistory();
  const handleClick = async () => {
    const redirectTo = routes.pagesHome.path;
    history.push(redirectTo);
  };
  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`Error · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.wrapper >
          <s.container>
              <s.declinedContainer>
                  <box-icon name="error-circle" color="#FFFFFF" animation='flashing' size='120px'></box-icon>
              </s.declinedContainer>
              <s.declineMessage>
                <div>Oh no, your payment failed</div>
                <s.declineMessageSub>
                  <div>Don't worry, you can use another payment method or try again</div>
                </s.declineMessageSub>
                <Button
                title={'Continue'}
                onClick={handleClick}
                />
              </s.declineMessage>
          </s.container>
      </s.wrapper>

    </>
  );
};

export default Cancel;
