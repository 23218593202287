
// App Imports
import { SET_ACTIVITY } from '../types';

export default function setGroups(activity) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVITY, activity,
    });
  };
}
