
// App Imports
import { SET_GROUPS } from '../types';

export default function setGroups(groups) {
  return (dispatch) => {
    dispatch({
      type: SET_GROUPS, groups,
    });
  };
}
