// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Authorize
export default function authorize(code) {
  const URL = `${URL_API}/gitlab/authorize`;
  return axios.get(URL, {
    params: code,
  });
}
