// Imports
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Report from './Report';

// App imports
import params from '../../../setup/config/params';
import * as s from './style';
// Component
const UserData = () => {
  // state
  const { user } = useSelector((state) => state.auth);
  const data = JSON.parse(user);
  const { groups } = useSelector((state) => state.auth);

  const handleCheck = (name) => {};
  const listGroups = groups.map((groups) => (
    <React.Fragment key={groups.id}>
      <li
        onClick={() => {
          handleCheck(groups.id);
        }}
      >
        <a href='#'>{groups.name}</a>{' '}
      </li>
    </React.Fragment>
  ));

  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`User Report · ${params.site.name}`}</title>
      </Helmet>
      {/* content */}
      <Report title={'Progress Report'}></Report>
    </>
  );
};

export default UserData;
