// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function userData(user) {
  return axios.get(`${URL_API}/user/userdata`, {
    params: { userdata: user },
  });
}
