
// App Imports
import { SET_AUTH } from '../types';

export default function setAuth(auth) {
  return (dispatch) => {
    dispatch({
      type: SET_AUTH, auth,
    });
  };
}
