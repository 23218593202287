// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function subscriptionRetrive(stripeid) {
  return axios.get(`${URL_API}/stripe/subscriptionRetrive`, {
    params: { id: stripeid },
  });
}
