import React from 'react';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: '20px',
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: 250,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: 'rgba(0, 0, 0, 0.4);' };
  },
};

function CustomSelect({ style, label, options, onChange, placeholder }) {
  return (
    <div style={{ ...style, zIndex: 9999 }}>
      <Select
        theme={(theme) => ({
          ...theme,
          borderRadius: '20px',
          colors: {
            ...theme.colors,
            primary25: '#F1F1F1',
            primary: '#F17300',
          },
        })}
        styles={customStyles}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
      />
    </div>
  );
}

export default CustomSelect;
