import styled from '@emotion/styled';

export const wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #0B315E;
  box-shadow: -10px -10px 20px rgba(255, 255, 255, 0.25);
`;

export const homeContainer = styled.div`
height: 100%;
width: 100%;
display: grid;
grid-template-columns: 1fr;
grid-template-rows: .4fr 2fr .1fr;
justify-items: center;
`;

export const wrapperText = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: 30px 60px;
padding-top: 50px;
color: #FFFFFF;
`;

export const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
`;

export const authContainer = styled.div`
display:grid;
grid-template-columns: 1fr;
grid-template-rows: .4fr .2fr .6fr 1fr;
width: 384px;
height: 560px;
background: #F9F9F9;
border-radius: 10px;
`;

export const userProfile = styled.div`
display:grid;
grid-template-columns: .1fr 1fr;
`;

export const textProfile = styled.div`
display:grid;
grid-template-columns: 1fr;
grid-template-rows: 1fr 1fr;
padding-left: 8px;
padding-top: 5px;
margin: 12px;
`;

export const nameText = styled.div`
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
color: #263742;
`;

export const emailText = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: rgba(139, 144, 150, 0.8);
`;

export const description = styled.div`
display:grid;
grid-template-columns: 1fr;
grid-template-rows: 1fr 1fr;
`;

export const descriptionText = styled.div`
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
padding-left: 24px;
padding-right: 24px;
color: #263742;
`;

export const descriptionText2 = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
padding-left: 24px;
padding-right: 24px;
color: #EB5757;
`;

export const listContainer = styled.div`
display:grid;
align-items: center;
justify-content: center;
margin: 24px;
`;

export const buttonContainer = styled.div`
display:grid;
align-items: center;
justify-content: center;
margin: 24px;
`;

export const footerText = styled.div`
font-family: Lato;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: #ffffff;
`;

export const Icon = styled.img`
  color: #ffffff;
  height: 40px;
  width: 50px;
`;
