
// App Imports
import { SET_TOKEN } from '../types';

export default function setRefreshToken(token) {
  return (dispatch) => {
    dispatch({
      type: SET_TOKEN, token,
    });
  };
}
