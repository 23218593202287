// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function updateSubscriptionGroup(id, quantity, newMembers, userData, group) {
  return axios.get(`${URL_API}/stripe/updateSubscriptionGroup`, {
    params: { subscriptionId: id, productQuantity: quantity, members: newMembers, user: userData, group },
  });
}
