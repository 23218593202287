// Imports
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import 'boxicons';
import { logout } from '../../user/api/actions/query';

// UI imports
import * as s from './styles.js';

// Component
const Menu = () => {
  const { user, section } = useSelector((state) => state.auth);
  const data = JSON.parse(user);

  const dispatch = useDispatch();

  const onLogout = () => {
    // eslint-disable-next-line no-alert
    const check = window.confirm('Are you sure you want to logout?');

    if (check) {
      dispatch(logout());
    }
  };

  // render
  return (
    <>
      <s.DashboardWrapper>
        <s.DashboardContainer>
          <s.profileD>
            <s.profileSub1>Home</s.profileSub1>
            <s.profileSub2>{data.name}</s.profileSub2>
            <s.profileSub3>Dev</s.profileSub3>
          </s.profileD>

          <Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
            <s.overviewD >
              <box-icon color={section === 'home' ? '#f08421' : '#8B9096'} name='grid-alt'></box-icon>
              <s.overviewText style={{ color: section === 'home' ? '#f08421' : '' }}>Overview</s.overviewText>
            </s.overviewD>
          </Link>

          <s.projectD>
            <box-icon color={section === 'report' ? '#f08421' : '#8B9096'} name='detail'></box-icon>
            <Link to={'/data'} style={{ textDecoration: 'none' }}>
              <s.projectText style={{ color: section === 'report' ? '#f08421' : '' }}>Progress Report</s.projectText>
            </Link>

            <box-icon color={section === 'projects' ? '#f08421' : '#8B9096'} name='collection'></box-icon>
            <Link to={'/projects'} style={{ textDecoration: 'none' }}>
              <s.projectText style={{ color: section === 'projects' ? '#f08421' : '' }}>Projects</s.projectText>
            </Link>
            <box-icon color={section === 'groups' ? '#f08421' : '#8B9096'} name='columns'></box-icon>
            <Link to={'/groups'} style={{ textDecoration: 'none' }}>
              <s.projectText style={{ color: section === 'groups' ? '#f08421' : '' }}>Groups</s.projectText>
            </Link>
          </s.projectD>

          <Link to={'/profile'} style={{ textDecoration: 'none' }}>
            <s.accountD>
              <box-icon color={section === 'profile' ? '#f08421' : '#8B9096'} name='id-card'></box-icon>
              <s.accountText style={{ color: section === 'profile' ? '#f08421' : '' }}>Account</s.accountText>
            </s.accountD>
          </Link>
          <s.profile>
            <box-icon name='log-out' color='#8B9096'></box-icon>
            <s.overviewText style={{ cursor: 'pointer' }} onClick={onLogout}>Logout</s.overviewText>
          </s.profile>

        </s.DashboardContainer>
      </s.DashboardWrapper>
    </>
  );
};

export default Menu;
