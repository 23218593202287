// Imports
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import 'boxicons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import List from '../../../ui/List/list';
import Button from '../../../ui/Button/CustomButton';
import { register, loginSetUserLocalStorage, stripeRetrive, setAuth, loginSetUser } from '../../user/api/actions/query';
import routes from '../../../setup/routes';

import 'react-toastify/dist/ReactToastify.css';

import params from '../../../setup/config/params';
import * as s from './style';
// UI imports
toast.configure();

const Checkout = () => {
  // TODO Write in DB subscription ACtive when payment is success
  const token = (window.localStorage.getItem('token'));
  const user = (window.localStorage.getItem('user'));
  const groups = (window.sessionStorage.getItem('groups'));
  const members = (window.sessionStorage.getItem('members'));
  const user2 = JSON.parse(user);
  const groupsFormated = JSON.parse(groups);
  const membersFormated = JSON.parse(members);
  const [stripedata, setStripedata] = useState('');

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    getStripeData();
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();

  const getStripeData = async () => {
    const params2 = new URLSearchParams(window.location.search);
    const id = params2.get('id');
    if (id) {
      const data = await stripeRetrive(id);
      setStripedata(data.data.result.data.session);
    }
  };

  const handleClick = async () => {
    // eslint-disable-next-line no-console
    console.log(stripedata, 'testing');
    const data = await register(user, groupsFormated, membersFormated, stripedata);
    const { dataUser } = data?.data?.result.data;

    loginSetUserLocalStorage(token, dataUser);
    // TODO refactor, Set activities, projects, userData REDUX
    dispatch(loginSetUser(token, user));
    if (data) {
      dispatch(setAuth('dashboard'));
      const redirectTo = routes.userDashboard.path;
      history.push(redirectTo);
    }
  };

  // render
  return (
    <>
      {/* meta */}
      <Helmet>
        <title>{`Success · ${params.site.name}`}</title>
      </Helmet>

      {/* content */}
      <s.wrapper >
        <s.container>
          <s.paymentHeader>
          <box-icon
                color='green'
                size='lg'
                name='badge-check'
              ></box-icon>
              <h1 >Congratulations</h1>
              <h4>The Operation has been successfully completed</h4>
          </s.paymentHeader>
          <s.paymentContent>
            <h3 style={{ display: 'grid', alignItems: 'center' }}>Your Resume</h3>
            <s.paymentDescription>
            <List
            title={'Groups'}
            members={groupsFormated}
            ></List>
            <List
            title={'Members'}
            members={membersFormated}
            ></List>
            </s.paymentDescription>
            <Button
            title={'Continue'}
            disable={!stripedata}
            onClick={handleClick}
            ></Button>
          </s.paymentContent>
        </s.container>
      </s.wrapper>

    </>
  );
};

export default Checkout;
