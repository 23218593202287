import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#F08421',
    fontSize: '12px',
    color: '#FFFFFF',
    borderRadius: '50px',
    boxShadow: 'unset',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#F08421',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: 0,
  },
}));

export default function CustomizedButtons({ title, type, src, onClick, disable = false, style }) {
  const classes = useStyles();

  return (
    <div>
      <ColorButton
        elevation={0}
        disabled = {disable}
        variant='contained'
        color='primary'
        className={classes.margin}
        href={src}
        onClick={onClick}
        target='_blank'
      >
        <h4 style={ style }>{title}</h4>
      </ColorButton>
    </div>
  );
}
