import styled from '@emotion/styled';

export const wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const container = styled.div`
height: 100%;
width: 100%;
display: grid;
grid-template-rows: .3fr .5fr .2fr;
justify-items: center;
`;

export const declinedContainer = styled.div`
display: grid;
width: 100%;
align-items: center;
justify-items: center;
background-color: #F08421;
`;

export const declineMessage = styled.div`
text-align: center;
  color: gray;
  font-size: 30px;
  font-weight: bold;
  margin: 50px;
`;

export const declineMessageSub = styled.div`
  color: gray;
  font-size: 20px;
  margin: 20px;
  padding-bottom: 50px;
`;

