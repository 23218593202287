
// App Imports
import { SET_PROJECTS } from '../types';

export default function setGroups(projects) {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECTS, projects,
    });
  };
}
