// Imports
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App Imports
import routes from '../../../setup/routes';

// Component
const CheckGroups = () => {
  const { selectedGroups } = useSelector((state) => state.auth);
  return selectedGroups.length > 0 ? <Redirect to={routes.PagesSubscription2.path} /> : <Redirect to={routes.PagesSubscription.path} />;
};

export default CheckGroups;
