// Imports
import axios from 'axios';

// App imports
import { URL_API } from '../../../../../setup/config/env';

// Profile
export default function stripeCheckout(quantity, trial) {
  return axios.get(`${URL_API}/stripe/checkout`, {
    params: { quantity, trial },
  });
}
