import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Assessment from '@material-ui/icons/Assessment';
import Dashboard from '@material-ui/icons/Dashboard';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '.5fr .5fr',
    alignItems: 'center',
    border: '1px solid #DDDDDD',
    width: '100%',
  },
  test: {
    'max-width': '100%',
    height: '55px',
    color: 'grey',
  },
  selected: {
    color: 'blue',
  },
});

export default function SimpleBottomNavigation({ value, changeValue }) {
  const classes = useStyles();
  return (
    <BottomNavigation
      value={value}
      onChange={changeValue}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        className={classes.test}
        value={false}
        label='Timeline'
        icon={<Assessment />}
      />
      <BottomNavigationAction
        className={classes.test}
        value={true}
        label='Dashboard'
        icon={<Dashboard />}
      />
    </BottomNavigation>
  );
}
